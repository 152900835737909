import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Programa de incursión"/>
            <Banner title="Programa de incursión"/>
            <section className="service_details_area aspectos servicios">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">Este paquete de soluciones se enfoca a aquellas empresas privadas y/o
                            familiares que
                            tienen contemplada su entrada a los mercados de deuda, pero, aún, tienen muchos factores por
                            definir,
                            como: el instrumento a emitir, el monto, la fecha de salida, entre otras. En esta solución
                            IRStrat se enfoca en aclarar el panorama del cliente, recomendar las mejores opciones de
                            instrumentos
                            a emitir,
                            acortar el tiempo de salida go-to-market, y facilitar la ejecución de la colocación. Este
                            paquete incluye lo siguiente:</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="service_left_sidebar">
                                <ul className="nav nav-tabs service_tab mb_50" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active show" id="paquete1-tab" data-toggle="tab"
                                           href="#paquete1" role="tab"
                                           aria-controls="paquete1" aria-selected="true">Paquete 1</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete2-tab" data-toggle="tab" href="#paquete2"
                                           role="tab"
                                           aria-controls="paquete2" aria-selected="false">Paquete 2</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete3-tab" data-toggle="tab" href="#paquete3"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Paquete 3</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="paquete1" role="tabpanel"
                                     aria-labelledby="paquete1-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#accordion"
                                                               href="#collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Plan estratégico
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse1" className="collapse show" aria-expanded="true"
                                                         role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Evaluación
                                                                    inicial de capacidades de emisión
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    matriz de evaluación de alcances
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definición de
                                                                    calendario go-to-market
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Núcleo de mensaje
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    tesis de inversión
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    de ventaja competitiva
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    la narrativa de la historia
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Estructura y funciones
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    de departamento de RI
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definición de
                                                                    funciones de reporte
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definición de
                                                                    funciones de relaciones con inversionistas
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Reclutamiento
                                                                    estratégico
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete2" role="tabpanel"
                                     aria-labelledby="paquete2-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p2_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p2_accordion"
                                                               href="#p2_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p2_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Capacitación al equipo
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Entrenamiento
                                                                    en finanzas corporativas
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Entrenamiento
                                                                    en comunicación financiera
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Capacitación en
                                                                    marco regulatorio financiero
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Mapeo de
                                                                    procesos de reporte
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p2_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Cumplimiento regulatorio
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    acopio de información financiera
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    procesamiento e interpretación financiera
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Alcance de
                                                                    calidad de reporte CNBV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Alcance de
                                                                    calidad de reporte BMV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Alcance de
                                                                    calidad de reporte BIVA
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Bases
                                                                    preliminares de cumplimiento SEC
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Desarrollo de materiales</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Reporte
                                                                    trimestral de resultados
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Informe anual
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentación
                                                                    corporativa
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Sitio de RI
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete3" role="tabpanel"
                                     aria-labelledby="paquete3-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p3_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p3_accordion"
                                                               href="#p3_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p3_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Mejores prácticas</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    gobierno corporativo
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    máxima calidad de reporte
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    fidelidad de reporte
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p3_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Agenda estratégica</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Acercamiento a
                                                                    agentes colocadores
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Acercamiento a
                                                                    inversores institucionales
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Acercamiento a
                                                                    calificadoras
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Acercamiento a
                                                                    medios financieros especializados
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Benchmarking</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Definición de
                                                                    sector y pares
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparativos de
                                                                    valuaciones
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparativos de
                                                                    resultados
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparativos
                                                                    go-to-market
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
